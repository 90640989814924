@tailwind base;
@tailwind components;
@tailwind utilities;


/* variable */

/* gold color = text-yellow-600 */

/* font-family */


.table-responsive::-webkit-scrollbar {
    width: 0px;
    height: 4px
}

.table-responsive::-webkit-scrollbar-track {
    background-color: #E9EDF1;
    border-radius: 100px;
    box-shadow: 0px 1px 3px gray inset;
}

.table-responsive::-webkit-scrollbar-thumb {
    background-image: linear-gradient(180deg, #4e4e4e 0%, #686868 99%);
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    border-radius: 100px;
}

.table-responsive {
    scrollbar-width: thin;
}

.table-responsive {
    scrollbar-color: rgb(112, 112, 112);
    scrollbar-width: thin;
}